import React from 'react'
import cx from 'classnames'

export interface SpacerProps {
  data: {
    spacing?: string
    backgroundColor?: string
  }
}

export const Spacer = ({ data }: SpacerProps) => {
  const { spacing, backgroundColor } = data
  return (
    <div className={cx('rel spacer block pt05', backgroundColor)}>
      <div
        className={cx('', {
          m30: spacing === 'pys',
          'm30 m50': spacing === 'pym',
          'm30 m70': spacing === 'pyl',
        })}
      />
    </div>
  )
}
