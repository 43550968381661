import React from 'react'
import cx from 'classnames'

export interface StatBlockProps {
  data: {
    items: any[]
  }
}

export const StatBlock = ({ data }: StatBlockProps) => {
  const { items, backgroundColor, circleBackground } = data

  return (
    <div
      className={cx('x pys my1 stat__wrapper', backgroundColor, {
        bcraspberry: backgroundColor === undefined,
      })}
    >
      <div className="container--l outer mxa py2 p1 ac x">
        <div className="f fw jcb fw ais">
          {items?.map(item => (
            <div
              key={item._key}
              className="x ac f stat__single jcc ais fw col-m-3"
            >
              <div
                className={cx('stat__circle f jcc aic', circleBackground, {
                  cb: circleBackground === 'bcraspberry',
                  'bcpurple cw': circleBackground === undefined,
                })}
              >
                <h3 className="h1 m0 p0">{item.title}</h3>
              </div>
              <div className="x stat__single-snippet cw">
                <p className="ss18 bold">{item.snippet}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
