import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client'
import { FC, ReactNode, createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { ampli } from '../ampli'
import React from 'react'

const { GATSBY_AMPLITUDE_EXPERIMENT_CLIENT_ID } = process.env

export const AmplitudeExperimentContext = createContext<
  ExperimentClient | undefined
>(undefined)

/**
 * Get the value of an experiment or feature flag
 * Configure experiments in Amplitude Experiment dashboard
 *
 * value is undefined until AE loads user data
 */
export function getVariantForFlag(
  flag: string | undefined
): undefined | 'control' | 'treatment' {
  const experimentClient = useContext(AmplitudeExperimentContext)

  if (!experimentClient || !flag) {
    return undefined
  }

  const variant = experimentClient?.variant(flag)

  const value = variant?.value

  if (value) {
    return value === 'on' || value === 'treatment' ? 'treatment' : 'control'
  }

  return undefined
}

export const AmplitudeExperimentProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  if (typeof window !== 'undefined') {
    const amplitudeExperiment = Experiment.initializeWithAmplitudeAnalytics(
      GATSBY_AMPLITUDE_EXPERIMENT_CLIENT_ID!
    )
    const { data: experimentClient } = useQuery(
      ['amplitude-experiment-client'],
      async () => {
        return amplitudeExperiment.fetch({
          device_id: ampli.client.getDeviceId(),
        })
      }
    )

    return (
      <AmplitudeExperimentContext.Provider value={experimentClient}>
        {children}
      </AmplitudeExperimentContext.Provider>
    )
  }

  return (
    <AmplitudeExperimentContext.Provider value={undefined}>
      {children}
    </AmplitudeExperimentContext.Provider>
  )
}
