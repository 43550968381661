import React, { useState } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import { Serializer } from 'src/utils/serializer'

import { AnnotationArrow, AnnotationClose } from 'src/components/svgs'

export const EditorialAnnotation = ({ data }) => {
  const [open, setOpen] = useState(false)
  const {
    children,
    mark: { text },
  } = data

  return (
    <React.Fragment>
      <div
        className={cx('abs mono ss10 nls sm14 editorial__annotation left', {
          'is-open': open,
        })}
      >
        <div className="editorial__annotation-text  p1">
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
        <button
          aria-label="right arrow"
          onClick={() => setOpen(!open)}
          className="editorial__annotation-arrow cp abs bcw top right"
        >
          {open ? <AnnotationClose /> : <AnnotationArrow />}
        </button>
      </div>
      <span className="bold cp">{children}</span>
    </React.Fragment>
  )
}
