import React from "react";

const StarIcon = () => {
  return  (
    <svg height="12px" width="12px" version="1.1" viewBox="0 0 53.867 53.867" fill="#ef308d">
      <g id="SVGRepo_bgCarrier"></g>
      <g id="SVGRepo_tracerCarrier"></g>
      <g id="SVGRepo_iconCarrier">
      <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 ">
      </polygon>
      </g></svg>
   )
}
export default StarIcon;