import React from 'react'
import cx from 'classnames'
import { TableModuleProps } from './interfaces/TableModule'

export const TableModule = ({ data }: { data: TableModuleProps }) => {
  const { title, backgroundColor, spacing, subTable, table, deepLinkId } = data

  return (
    <div
      id={deepLinkId}
      className={cx(backgroundColor, {
        cw: backgroundColor === 'bcb',
      })}
    >
      <div className={cx('container--l mxa outer p1', spacing)}>
        <div className="x ba br">
          {title && <h3 className="p1 m0 x table__header">{title}</h3>}
          {subTable?.rows.map(row => (
            <div
              key={row._key}
              className={cx('f jcb aist table__row no-bars', {
                table__3: row.cells.length === 3,
              })}
            >
              {row.cells.map((cell, i) => (
                <div
                  key={i}
                  className="h4 basis bold"
                  dangerouslySetInnerHTML={{ __html: cell }}
                />
              ))}
            </div>
          ))}
          {table?.rows.map(row => (
            <div
              key={row._key}
              className={cx('f jcb aist table__row', {
                table__3: row.cells.length === 3,
              })}
            >
              {row.cells.map((cell, i) => (
                <div
                  key={i}
                  className="sm18"
                  dangerouslySetInnerHTML={{ __html: cell }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
