import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import jump from 'jump.js'
import slugify from 'slugify'

import { handleSlug } from 'src/utils/utils'

import { EditorialArrow } from 'src/components/svgs'

import { FeatureCard } from 'src/components/inline/featureCard'
import { ButtonBlock } from 'src/components/inline/buttonBlock'
import { InlineCTA } from 'src/components/inline/inlineCta'
import { StatBlock } from 'src/components/inline/statBlock'
import { InlineImageAndText } from 'src/components/inline/inlineImageAndText'
import { EditorialAnnotation } from 'src/components/editorial/editorialAnnotation'
import { EditorialImageAndText } from 'src/components/editorial/editorialImageAndText'
import { EditorialImageCarousel } from 'src/components/editorial/editorialImageCarousel'
import { TableModule } from 'src/components/global/tableModule'
import {
  FeaturedTopic,
  FeaturedTopicProps,
} from 'src/components/editorial/featuredTopic'

const EditorialWrap = ({ children }: { children: any }) => {
  return (
    <div className="container--l px1 mxa outer rel f jce">
      <div className="container--e x">{children}</div>
    </div>
  )
}

const handleStyle = (props: any) => {
  const { style } = props.node
  switch (style) {
    case 'small':
      return <p className="ss10 mono sm14">{props.children}</p>
    case 'large':
      return <p className="ss18 sm25">{props.children}</p>
    case 'h5':
      return <h5 className="caps w100">{props.children}</h5>
    default:
      // @ts-ignore
      return BlockContent.defaultSerializers.types.block(props)
  }
}

function zeroOutNum(x) {
  return 0
}

const zeroOut = zeroOutNum()
let start = zeroOut + 1

let info = zeroOut

const emptyMarks = {
  internalLink: props => '',
  annotation: props => '',
  annotationLink: props => '',
  footnote: props => '',
  externalLink: props => '',
}

export const EditorialSerializer = {
  marks: {
    internalLink: (props: { children: any; mark: any }) => {
      if (props.mark.reference) {
        return (
          <Link
            className={cx(' inline-block', {
              'un-underline ls1 button s14 my1 ':
                props.mark.linkType === 'button',
              'small underline bold ls1': props.mark.linkType === 'text',
            })}
            role={props.mark.linkType}
            to={handleSlug(
              props.mark.reference._type,
              props.mark.reference.content.main.slug.current
            )}
          >
            {props.children}
          </Link>
        )
      } else {
        return <span>{props.children}</span>
      }
    },
    annotation: (props: { children: any; mark: any }) => {
      return <EditorialAnnotation data={props} />
    },
    annotationLink: (props: {}) => {
      return <span className="bold underline">Annotation link?</span>
    },
    footnote: (props: { children: any }) => {
      return (
        <span className="rel editorial__footnote inline-block">
          {props.children}
          <span className="editorial__footnote-num abs cp bold small js-footnote" />
        </span>
      )
    },
    externalLink: (props: { children: any; mark: any }) => {
      return (
        <a
          className="ls1 inline-block underline"
          role="button"
          href={props.mark.url}
          target="_blank"
        >
          {props.children}
        </a>
      )
    },
  },
  types: {
    featureCard: (props: { node: {} }) => {
      return <FeatureCard data={props.node} />
    },
    statBlock: (props: { node: {} }) => {
      return <StatBlock data={props.node} />
    },
    cta: (props: { node: {} }) => {
      return <InlineCTA data={props.node} />
    },
    imageAndText: (props: { node: {} }) => {
      return <InlineImageAndText data={props.node} />
    },
    featuredTopic: (props: {}) => {
      return <FeaturedTopic data={props.node as FeaturedTopicProps['data']} />
    },
    editorialImageAndText: (props: { node: {} }) => {
      return <EditorialImageAndText data={props.node} />
    },
    editorialImageCarousel: (props: { node: {} }) => {
      return <EditorialImageCarousel data={props.node} />
    },
    tableModule: (props: { node: {} }) => {
      return <TableModule data={props.node} />
    },
    sectionHeader: (props: { node: {} }) => {
      return (
        <EditorialWrap>
          <h2
            className="mt1 pt30 block al x"
            id={slugify(props.node.title)?.replace(/[^\w\s]/gi, '')}
          >
            {props.node.title}
          </h2>
        </EditorialWrap>
      )
    },
    buttonBlock: (props: { node: {} }) => {
      return <ButtonBlock data={props.node} />
    },
    block: (props: {
      children: any[]
      node: {
        style?: string
      }
    }) => {
      return <EditorialWrap>{handleStyle(props)}</EditorialWrap>
    },
  },
  list: props => (
    <EditorialWrap>
      {props.type === 'bullet' ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      )}
    </EditorialWrap>
  ),
}

export const EditorialSectionSerializer = {
  marks: emptyMarks,
  list: props => '',
  types: {
    featureCard: props => '',
    statBlock: props => '',
    sectionHeader: props => {
      info++
      return (
        <React.Fragment>
          {info === 1 && <span className="bold mt1 block">Content</span>}
          <a
            className="pt1 f jcs ais al underline editorial__header-jumps"
            onClick={e => {
              e.preventDefault()
              jump(`#${slugify(props.node.title)?.replace(/[^\w\s]/gi, '')}`, {
                offset: -200,
              })
            }}
          >
            <EditorialArrow className="m0 p0 mr01" />
            <span>{props.node.title}</span>
          </a>
        </React.Fragment>
      )
    },
    featuredTopic: props => '',
    cta: props => '',
    imageAndText: props => '',
    editorialImageAndText: props => '',
    editorialImageCarousel: props => '',
    tableModule: props => '',
    block: props => '',
  },
}

export const FootNoteSerializer = {
  marks: emptyMarks,
  list: props => '',
  types: {
    featureCard: props => '',
    statBlock: props => '',
    cta: props => '',
    imageAndText: props => '',
    sectionHeader: props => '',
    featuredTopic: props => '',
    editorialImageAndText: props => '',
    editorialImageCarousel: props => '',
    tableModule: props => '',
  },
}

let end = zeroOut

export const EditorialFootnoteSerializer = {
  marks: emptyMarks,
  list: props => '',
  types: {
    featureCard: props => '',
    cta: props => '',
    statBlock: props => '',
    sectionHeader: props => '',
    imageAndText: props => '',
    featuredTopic: props => '',
    editorialImageAndText: props => '',
    editorialImageCarousel: props => '',
    tableModule: props => '',
    block: props => {
      const defs = props.node.markDefs
      if (defs && defs.length > 0) {
        return defs.map(def => {
          if (def._type === 'footnote') {
            end++
            return (
              <React.Fragment>
                <h5 className="mono footnote__header js-footnote-reference hide caps">
                  References
                </h5>
                <div className="f footnote__block ss10 sm14 jcs ais">
                  <span className="mr05 mono js-footnote-bottom" />
                  <BlockContent
                    blocks={def.text}
                    serializers={FootNoteSerializer}
                  />
                </div>
              </React.Fragment>
            )
          }
          return ''
        })
      }
      return ''
    },
  },
}
