import React, { FormEvent, useState } from 'react'
import classes from './emailCapture.module.css'
import { Discorectangle, LeftThread, RightThread } from '../svgs'
import { EmailCaptureProps } from './interfaces/EmailCapture'
import { ampli } from '../../ampli'

export const EmailCapture = ({ data }: { data: EmailCaptureProps }) => {
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const { email } = form.elements
      ampli.emailCaptured({
      email: email.value,
      location: data.emailCaptureLocation,
    })

    setEmailSubmitted(true)
  }

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <h2 className={classes.title}>{data.title}</h2>
        <h3 className={classes.subTitle}>{data.subTitle}</h3>
        {emailSubmitted ? (
          <p className={classes.success}>We've got your Email !</p>
        ) : (
          <form onSubmit={(e: FormEvent) => handleSubmit(e)}>
            <input
              className={classes.input}
              type="email"
              name="email"
              aria-label="email"
              placeholder="Email*"
              required
            />
            <button className={classes.submit} type="submit">
              Sign Up
            </button>
          </form>
        )}
      </div>
      <LeftThread className={classes.leftThread} />
      <RightThread className={classes.rightThread} />
      <Discorectangle className={classes.discorectangle} />
    </div>
  )
}

export default EmailCapture
