import React from "react";
interface SvgProps {
    className?: string
}

export const HeroShapeOne = ({ className }: SvgProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 932 510" fill="currentColor" className={className}>
    <path d="M395.776 221.289C323.071 202.474 101.631 21.1075 0 0H932V510C921.739 475.625 902.957 398.592 861.913 333.46C810.609 252.046 737.317 233.954 653.764 210.434C570.211 186.914 576.075 290.039 515.975 277.375C455.876 264.71 486.658 244.809 395.776 221.289Z" fill="currentColor"/>
  </svg>
);

export const HeroShapeTwo = ({ className }: SvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1174 263" fill="currentColor" className={className}>
        <path d="M454.368 82.5098C360.519 72.6575 131.188 11.0523 0 0H1174V263C1160.76 245.001 1134.57 201.666 1081.59 167.562C1015.37 124.931 920.763 115.458 812.911 103.142C721.263 92.6772 595.77 97.3541 454.368 82.5098Z" fill="currentColor"/>
    </svg>
);
export const HeroShapeThree = ({ className }: SvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1175 263" fill="currentColor" className={className}>
        <path d="M534.091 86.4166C383.975 108.587 129.239 14.2129 0 0H1175V256.976C981.444 305.792 844.816 40.5266 534.091 86.4166Z" fill="currentColor"/>
    </svg>
);

export const HeroShapeFour = ({ className }: SvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1175 263" fill="currentColor" className={className}>
        <path d="M470 96.1505C285.24 111.849 142.033 74.7745 0 0H1175V263C862.527 210.142 1128.96 40.1598 470 96.1505Z" fill="currentColor"/>
    </svg>
);
