import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import cookie from 'js-cookie'
import { saveLead } from 'src/api/saveLead'

export interface LeadFormProps {
  data: {
    campaignName: string
    delayTrigger: number
    buttonName: string
    embedded: boolean
  }
}

const setUpLeadFormToSubmit = ({
  saveLead,
  campaignName,
}: {
  saveLead: any
  campaignName: string
}) => {
  // TODO: need to grab this in a more specific way than an iframe
  //@ts-ignore
  const form = document.querySelector('.ab-in-app-message')?.contentWindow
    .document.body
  //@ts-ignore
  const submitButton = form.querySelector('#submit')

  submitButton?.addEventListener('click', async () => {
    // TODO: need to grab other event properties
    // guaranteed by lead form contract
    //@ts-ignore
    if (form) {
      const email = form
        .querySelector('#email')
        //@ts-ignore
        .value.replace(/^\s+|\s+$/g, '')
        .toLowerCase()

      //@ts-ignore
      const otherInputs = form.querySelectorAll('input')
      const eventProperties = {}
      otherInputs.forEach(input => {
        if (input.id && input.value && input.id !== 'email') {
          //@ts-ignore
          eventProperties[input.id] = input.value
        }
      })
      try {
        await saveLead({ email: email, campaignName, eventProperties })
      } catch (err) {
        console.log('something went wrong', err)
      }
    }
  })
}

export const LeadForm = ({ data }: LeadFormProps) => {
  const [buttonVisible, setButtonVisible] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('@braze/web-sdk').then(
        ({
          initialize,
          openSession,
          subscribeToInAppMessage,
          showInAppMessage,
          logCustomEvent,
        }) => {
          initialize(process.env.GATSBY_BRAZE_WEB_API_KEY || '', {
            baseUrl: 'sdk.iad-02.braze.com',
            allowUserSuppliedJavascript: true,
            enableLogging: true,
            minimumIntervalBetweenTriggerActionsInSeconds: 0,
          })

          const hasSeenCampaign = () => {
            return !cookie.get(`open-${data?.campaignName}`)
          }

          const openCampaignModal = (
            message: any,
            container: any | undefined = undefined
          ) => {
            cookie.set(
              `open-${data?.campaignName}`,
              `open-${data?.campaignName}`,
              { expires: 1 }
            )
            if (container) {
              showInAppMessage(message, container, () => {
                setUpLeadFormToSubmit({
                  saveLead,
                  campaignName: data.campaignName,
                })

                const iframe = document.querySelector('iframe')

                if (iframe) {
                  var iframeDoc =
                    iframe.contentDocument || iframe.contentWindow?.document
                  var height = iframeDoc?.body?.scrollHeight
                  iframe.style.height = `${height! + 10}px`
                  iframe.style.boxShadow = `none`

                  const rootElement = document.querySelector('.ab-iam-root')

                  // this styling would be better in a css file
                  // but we would need to use psuedo selectors that don't have full support
                  // to scope it to just embedded

                  if (rootElement) {
                    //@ts-ignore
                    rootElement.style.position = 'relative'
                    //@ts-ignore
                    rootElement.style.zIndex = 1

                    const modalContent = iframeDoc?.querySelectorAll(
                      '.modal__content'
                    )
                    // needed to ensure that only embeded gets the correct background of cream
                    //@ts-ignore
                    modalContent.forEach(
                      el => (el.style.background = '#FCF4E9')
                    )
                  }

                  if (iframe.contentWindow) {
                    iframe.contentWindow.addEventListener(
                      'resize',
                      function () {
                        var iframe = document.querySelector('iframe')
                        if (iframe) {
                          var iframeDoc =
                            iframe.contentDocument ||
                            iframe.contentWindow?.document
                          var height = iframeDoc?.body?.scrollHeight
                          iframe.style.height = `${height!}px`
                        }
                      }
                    )
                  }
                }
              })
            } else {
              showInAppMessage(message, null, () => {
                setUpLeadFormToSubmit({
                  saveLead,
                  campaignName: data.campaignName,
                })
              })
            }
          }

          // later on need to filter for correct campaign using message.extras.title
          subscribeToInAppMessage((message: any) => {
            if (message.extras.title === data?.campaignName) {
              if (!data?.embedded) {
                setButtonVisible(true)
              }
              if (hasSeenCampaign()) {
                setTimeout(() => {
                  // it is possible someone has clicked on button since timeout started
                  if (hasSeenCampaign()) {
                    openCampaignModal(message)
                  }
                }, Number(data?.delayTrigger) * 1000)
              }
              const container = document.getElementById('braze-feed')
              const openModalButton = document.querySelector(
                '#openInAppMessageModal'
              )

              if (container) {
                openCampaignModal(message, container)
              } else if (openModalButton) {
                //@ts-ignore
                openModalButton.onclick = () => openCampaignModal(message)
              }
            }
          })

          openSession()
          logCustomEvent(`leadform-${data?.campaignName}-triggered`)
        }
      )
    }
  }, [])

  return (
    <div className="container--l outer mxa">
      {data?.buttonName && buttonVisible && (
        <button
          id="openInAppMessageModal"
          className={cx('inline-block button--join jcc aic cb f rel')}
          style={{ cursor: 'pointer' }}
        >
          <span className="z1 rel">{data?.buttonName || 'Learn More'}</span>
        </button>
      )}
      {data?.embedded && <div id="braze-feed"></div>}
    </div>
  )
}
