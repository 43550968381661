import React from 'react'
import { Helmet } from 'react-helmet'

const siteRoute = 'https://www.asktia.com'

export const SEO = ({
  pagePath,
  metaInfo,
  env,
}: {
  pagePath: string
  metaInfo: {
    metaTitle?: string
    metaDescription?: string
    openImageUrl?: string
    twitterImageUrl?: string
    twitterTitle?: string
    openTitle?: string
    openGraphDescription?: string
    twitterDescription?: string
    noIndex?: boolean
  }
  env: 'production' | 'development' | 'local' | 'staging'
}) => {
  const title = metaInfo
    ? metaInfo.metaTitle
      ? metaInfo.metaTitle
      : 'Ask Tia'
    : 'Ask Tia'
  const metaDescription = metaInfo
    ? metaInfo.metaDescription
      ? metaInfo.metaDescription
      : 'Ask Tia'
    : 'Ask Tia'
  const metaKeywords = 'ask tia, health, women, woman, wellness, clinic'
  const ogImage = metaInfo
    ? metaInfo.openImage
      ? metaInfo.openImage.asset.url
      : 'https://cdn.sanity.io/images/8qqycr4y/production/4eaae4b88cd6b4f41ebaa30a351d53ee2d6914ae-2400x1260.png'
    : 'https://cdn.sanity.io/images/8qqycr4y/production/4eaae4b88cd6b4f41ebaa30a351d53ee2d6914ae-2400x1260.png'
  const twitterImage = metaInfo
    ? metaInfo.twitterImage
      ? metaInfo.twitterImage.asset.url
      : 'https://cdn.sanity.io/images/8qqycr4y/production/4eaae4b88cd6b4f41ebaa30a351d53ee2d6914ae-2400x1260.png'
    : 'https://cdn.sanity.io/images/8qqycr4y/production/4eaae4b88cd6b4f41ebaa30a351d53ee2d6914ae-2400x1260.png'
  const openTitle = metaInfo
    ? metaInfo.openTitle
      ? metaInfo.openTitle
      : title
    : title
  const openGraphDescription = metaInfo
    ? metaInfo.openGraphDescription
      ? metaInfo.openGraphDescription
      : metaDescription
    : metaDescription
  const twitterTitle = metaInfo
    ? metaInfo.twitterTitle
      ? metaInfo.twitterTitle
      : title
    : title
  const twitterDescription = metaInfo
    ? metaInfo.twitterDescription
      ? metaInfo.twitterDescription
      : metaDescription
    : metaDescription

  const dontIndex = metaInfo?.noIndex || env !== 'production'

  // one trust integration at beginning of html head
  return (
    <Helmet title={title === 'Home' ? 'Ask Tia' : title}>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="b045d6a4-6b1e-486d-8d5a-c46a19c89365"
      ></script>
      <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

      <script>
        {`function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) {
                return true;
            }
        }

        function reloadOTBanner() {

            var otConsentSdk = document.getElementById("onetrust-consent-sdk");
            if (otConsentSdk) {
                otConsentSdk.remove();
            }

            if (window.OneTrust != null) {
                OneTrust.Init();

                setTimeout(function() {
                    OneTrust.LoadBanner();

                    var toggleDisplay = document.getElementsByClassName(
                        "ot-sdk-show-settings"
                    );

                    for (var i = 0; i < toggleDisplay.length; i++) {
                        toggleDisplay[i].onclick = function(event) {
                            event.stopImmediatePropagation();
                            window.OneTrust.ToggleInfoDisplay();
                        };
                    }
                }, 1000);
            }
        }`}
      </script>

      <html lang="en" />
      <meta http-equiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta property="og:locale" content="en_US" />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta
        property="og:url"
        content={`${siteRoute}/${pagePath ? pagePath : ''}`}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={`${openTitle}`} />
      <meta property="og:site_name" content="Ask Tia" />
      <meta property="og:description" content={openGraphDescription} />
      <meta name="twitter:site" content="@AskTia" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${twitterTitle}`} />
      <meta name="twitter:image:src" content={twitterImage} />
      <meta name="twitter:description" content={twitterDescription} />
      {dontIndex && <meta name="robots" content="noindex" />}
      <meta
        name="twitter:url"
        content={`${siteRoute}/${pagePath ? pagePath : ''}`}
      />
    </Helmet>
  )
}
