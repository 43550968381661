import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import cookie from 'js-cookie'

import { JoinWidgetProps } from './interfaces/JoinWidget'

export const getWidgetUrl = () => {
  if (typeof window !== 'undefined') {
    const setLocation = location.search ? `${location.search}&` : `?`
    return (
      process.env.GATSBY_WIDGET_URL +
      setLocation +
      `uuid=${cookie.get('UUID')}&page=${
        location.pathname.match(/([^\/]*)\/*$/)![1]
      }`
    )
  }
}

export const JoinWidget = ({ data }: { data: JoinWidgetProps }) => {
  const [url, setUrl] = useState('')
  const { text, backgroundColor, shapes, spacing, deepLinkId } = data

  useEffect(() => {
    // page=slug to the params url
    const setLocation = location.search ? `${location.search}&` : `?`
    setUrl(
      process.env.GATSBY_WIDGET_URL +
        setLocation +
        `uuid=${cookie.get('UUID')}&page=${
          location.pathname.match(/([^\/]*)\/*$/)![1]
        }`
    )

    function handleIframeMessage(event: any) {
      // Should we scroll the widget into view?
      if (event.data === 'scrollWidgetIntoView') {
        // Find the widget iframe
        const iframe = document.querySelectorAll('iframe[src*=widget]')[0]
        const headerHeight = document.getElementsByTagName('header')[0]
          .clientHeight

        // Move it into the viewport
        window.scrollTo({
          top:
            iframe.getBoundingClientRect().top +
            (window.pageYOffset || iframe.scrollTop) -
            headerHeight,
          behavior: 'auto',
        })
        return
      }
    }

    // Set up to permit the iframe to request various actions
    window.addEventListener('message', handleIframeMessage, false)

    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  return (
    <div
      id={deepLinkId}
      className={cx('joinWidget rel join__module', backgroundColor, spacing, {
        cw: backgroundColor === 'bcb',
        pyl: spacing === undefined,
        'cw bcb': backgroundColor === undefined,
      })}
    >
      <div className="container--900 f jcc aic ac outer mxa join__widget py2 al x rel z2">
        <iframe
          title="join tia"
          src={url}
          className="join__widget-iframe x rel z2 container--l mxa"
        />
        <svg
          className="join__widget-shape abs left top x y"
          width="527"
          height="794"
          viewBox="0 0 527 794"
          fill="none"
        >
          <path
            d="M504.554 754.008C524.622 696.714 541.85 48.0446 506.998 30.4273C472.147 12.81 94.2465 -24.04 36.4081 22.9927C-21.4304 70.0254 -1.86569 745.74 36.408 771.596C74.6816 797.453 484.486 811.303 504.554 754.008Z"
            fill="#fcf4e9"
            stroke="#ecdccd"
            stroke-width="4px"
          />
        </svg>
      </div>
    </div>
  )
}

export default JoinWidget
