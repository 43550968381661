import React, { useState, useEffect, useCallback, useRef } from 'react'
import cx from 'classnames'

import { useEmblaCarousel } from 'embla-carousel/react'

import { Arrow } from 'src/components/svgs'
import { LocationCard } from './locationCard'
import classes from './locationCarousel.module.css'

export interface LocationCarouselProps {
  data: {
    title: string
    backgroundColor?: string
    containerSize?: string
    locations: any[]
    spacing?: string
    delayTime: number
  }
}

export const useRecursiveTimeout = (callback: any, delay: number) => {
  const [isRunning, setIsRunning] = useState(false)
  const stop = useCallback(() => setIsRunning(false), [setIsRunning])
  const play = useCallback(() => setIsRunning(true), [setIsRunning])
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!isRunning) return
    let id = 0

    const tick = () => {
      if (!isRunning) return clearTimeout(id)
      savedCallback.current()
      requestAnimationFrame(() => (id = setTimeout(tick, delay)))
    }
    requestAnimationFrame(() => (id = setTimeout(tick, delay)))

    return () => {
      if (id) clearTimeout(id)
      stop()
    }
  }, [isRunning, delay, stop])

  return { play, stop }
}

export const LocationCarousel = ({ data }: LocationCarouselProps) => {
  const [slide, setSlide] = useState(1)

  const {
    title,
    backgroundColor,
    containerSize,
    spacing,
    locations,
    delayTime,
  } = data

  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
    speed: 10,
    containScroll: true,
    align: 'start',
  })

  const autoplay = useCallback(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }, [embla])

  const { play } = useRecursiveTimeout(autoplay, delayTime * 1000 || 5000)

  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setSlide(embla.selectedScrollSnap() + 1)
      })
    }
  }, [embla])

  useEffect(() => {
    play()
  }, [play])

  return (
    <div
      className={cx('rel carousel__text-wrapper', backgroundColor, spacing, {
        cw: backgroundColor === 'bcb',
        pyl: spacing === undefined,
        'cw bcb': backgroundColor === undefined,
      })}
    >
      <div
        className={`${classes.carousel} container--900 outer mxa carousel__text py2 p1 al x rel z2`}
      >
        <div className={cx('mxa', containerSize, classes.carouselContainer)}>
          <h2>{title}</h2>
          <div className="rel">
            <EmblaCarouselReact>
              <div className="f x aist">
                {locations.map(location => {
                  return (
                    <div
                      key={location._key}
                      className={`carousel__text-single card__carousel x`}
                    >
                      <LocationCard {...location} horizontal={true} />
                    </div>
                  )
                })}
              </div>
              <div
                className={cx('my1 py1 f jcs aic', {
                  cw: backgroundColor === 'bcb',
                  hide: locations.length <= 1,
                })}
              >
                <button
                  aria-label="carousel arrow"
                  className={cx('no-style carousel__arrow cursor', {
                    cw: backgroundColor === 'bcb',
                  })}
                  onClick={() => embla.scrollPrev()}
                >
                  <Arrow />
                </button>
                {locations.length < 6 ? (
                  <div className="m1 carousel__dots f jcc aic">
                    {locations?.map((location, i) => (
                      <span
                        key={i}
                        className={cx('dot block', {
                          'is-active': slide - 1 === i,
                        })}
                      />
                    ))}
                  </div>
                ) : (
                  <span className=" block m1">
                    {slide}/{locations.length}
                  </span>
                )}
                <button
                  aria-label="carousel arrow"
                  className={cx(
                    'no-style carousel__arrow cursor arrow__rotate',
                    {
                      cw: backgroundColor === 'bcb',
                    }
                  )}
                  onClick={() => embla.scrollNext()}
                >
                  <Arrow />
                </button>
              </div>
            </EmblaCarouselReact>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationCarousel
