import React from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

export interface EditorialImageAndTextProps {
  data: {
    imageCaption: {
      caption: string
      image: {
        asset: {
          _id: string
        }
      }
    }
    mobileImageCaption: {
      image: {
        asset: {
          _id: string
        }
      }
    }
    backgroundColor?: string
    containerSize?: string
    text: any[]
  }
}

export const EditorialImageAndText = ({ data }: EditorialImageAndTextProps) => {
  const {
    imageCaption: { caption, image },
    backgroundColor,
    containerSize,
    mobileImageCaption,
    text,
  } = data

  return (
    <div
      className={cx(
        'rel py1 pym oh image__text-quote-wrapper',
        backgroundColor
      )}
    >
      <div
        className={cx('x y oh rel mxa', containerSize, {
          'py1 outer container--xl': containerSize !== 'x',
        })}
      >
        <div className="abs x y top left f jcc z2 aic">
          {text && (
            <div className="container--e ac mxa p1 image__text-quote outer cw">
              <BlockContent blocks={text} serializers={Serializer} />
            </div>
          )}
        </div>
        {mobileImageCaption && (
          <Image
            imageId={mobileImageCaption.image.asset._id}
            alt={caption}
            className="image__text-quote-mobile x abs left z1 y"
          />
        )}
        <Image
          imageId={image.asset._id}
          alt={caption}
          className="x abs left top y"
        />
      </div>
    </div>
  )
}

export default EditorialImageAndText
