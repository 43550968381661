import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'

export const ColumnText = ({ text }) => {
  return (
    <div className="x col-m-5">
      <BlockContent blocks={text} serializers={Serializer} />
    </div>
  )
}
