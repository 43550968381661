import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import { useEmblaCarousel } from 'embla-carousel/react'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'
import { AnnotationArrow, SmallArrow } from 'src/components/svgs'

export interface EditorialImageCarouselProps {
  data: {
    imageCaption: {
      caption: string
      image: {
        asset: {
          _id: string
        }
      }
    }
    items: any[]
  }
}

export const EditorialImageCarousel = ({
  data,
}: EditorialImageCarouselProps) => {
  const { spacing, layoutStyle, items } = data
  const [slide, setSlide] = useState(1)

  const [playing, setPlaying] = useState(false)
  const videoPlayer = React.createRef()

  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    // loop: true,
    // containScroll: true,
    // align: 'start'
  })

  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setSlide(embla.selectedScrollSnap() + 1)
      })
    }
  }, [embla])

  const play = () => {
    const videoRef = videoPlayer.current
    if (playing) {
      videoRef.pause()
      setPlaying(false)
    } else {
      videoRef.play()
      setPlaying(true)
    }
  }

  return (
    <div
      className={cx(
        'rel py1 f pym jce ais px1 outer container--l mxa',
        spacing,
        {
          inline: layoutStyle !== 'wide',
        }
      )}
    >
      <div
        className={cx('editorial__image-c x jce aic', {
          inline: layoutStyle !== 'wide',
        })}
      >
        <EmblaCarouselReact>
          <div className="f x editorial__image-c-wrapper">
            {items?.map(item => (
              <div
                key={item._key}
                className={cx('x editorial__image-carousel', {
                  inline: layoutStyle !== 'wide',
                })}
              >
                {item.video ? (
                  <div
                    className={cx({
                      'is-playing': playing,
                    })}
                  >
                    <div
                      onClick={() => play()}
                      className="player__button cursor z2 abs"
                    >
                      <span />
                    </div>
                    <video
                      ref={videoPlayer}
                      onClick={() => play()}
                      className="x"
                      poster={item.imageCaption.image.asset.url}
                    >
                      <source src={item.video} />
                    </video>
                  </div>
                ) : (
                  <Image
                    className="x br oh"
                    imageId={item.imageCaption.image.asset?._id}
                    alt={item.imageCaption.caption}
                  />
                )}
              </div>
            ))}
          </div>
        </EmblaCarouselReact>
        <div className="editorial__image-t-wrapper">
          {items?.map((item, i) => (
            <div
              key={i}
              className={cx('mono ss10 sm14 f fw jcb ais editorial__image-t', {
                'is-active': i + 1 === slide,
              })}
            >
              <div className="editorial__image-arrow cp">
                {item.text && <AnnotationArrow />}
              </div>
              <div className="editorial__image-text">
                {item.text && (
                  <BlockContent blocks={item.text} serializers={Serializer} />
                )}
                {item.imageCaption.credit && (
                  <span className="karla cgray block mt1 pt05">
                    {item.imageCaption.credit}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        {items?.length > 1 && (
          <div className="editorial__image-controls aic abs">
            <button
              className="no-style rotate"
              onClick={() => embla.scrollPrev()}
            >
              <SmallArrow />
            </button>
            {items?.map((item, i) => (
              <div
                key={i}
                className={cx('editorial__image-controls-dot', {
                  'is-active': i + 1 === slide,
                })}
              />
            ))}
            <button className="no-style" onClick={() => embla.scrollNext()}>
              <SmallArrow />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
