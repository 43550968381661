import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

export interface FeatureCardProps {
  data: {
    text: any[]
    image: {
      asset: {
        _id: string
      }
    }
  }
}

export const FeatureCard = ({ data }: FeatureCardProps) => {
  const {
    text,
    image,
  } = data
  return (
    <div className="col-m-3 inline-block feature__card">
      {image?.asset?._id && <Image imageId={image?.asset?._id} className="col-m-4 x feature__card-image" />}
      <div className="feature__card-text x">
        <BlockContent blocks={text} serializers={Serializer} />
      </div>
    </div>
  )
}
