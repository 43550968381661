import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'

import { CtaShape, CtaBoobs } from 'src/components/svgs'
import {Image} from "../image";

export interface CTAProps {
  data: {
    text: any[]
    backgroundColor?: string
    ctaImage: { asset: { _id: string } };
  }
}

export const CTA = ({ data }: CTAProps) => {
  const { text, backgroundColor, ctaImage } = data
  return (
    <div
      className={cx('pyl py1 pys cta rel', backgroundColor, {
        cw: backgroundColor === 'bcb',
        'cw bcb': backgroundColor === undefined,
      })}
    >
      <div className="container--l outer mxa f jcs ais py2 p1 al x rel cta__background">
        <div className="cta__inner">
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
        {ctaImage?.asset?._id && <div className="x cw rel col-xs-24 col-1 ctaImg">
          <Image imageId={ctaImage?.asset?._id} alt={''}/>
        </div>}
      </div>
    </div>
  )
}
