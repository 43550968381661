import React, { useCallback, useEffect, useState, useRef } from 'react'
import cx from 'classnames'
import { useLoads } from 'react-loads'

import { SingleSelector } from 'src/components/singleSelector'
import { namesToAbbreviations } from '../../utils/states.ts'
import { DepartmentsURL } from 'src/constants/Departments'
import { Job, FormattedJob } from './interfaces/Job'
import { Department, FetchedDepartments } from './interfaces/Department'
import { SingleSelectorProps } from 'src/interfaces/SingleSelector'
import toAlphabetically from '../../utils/toAlphabetically'

export interface JobsProps {
  data: {
    enableJobs?: boolean
    title?: string
  }
}

export const Jobs = ({ data }: JobsProps) => {
  const [jobList, setJobsList] = useState<FormattedJob[]>([])
  const [teamFilter, setTeamFilter] = useState('all')
  const [cityFilter, setCityFilter] = useState('all')
  const { title, enableJobs } = data
  const [locations, setLocations] = useState<SingleSelectorProps[]>([])
  const [teams, setTeams] = useState<SingleSelectorProps[]>([])
  const [
    haveTeamsAndLocationsBeenParsed,
    setHaveTeamsAndLocationsBeenParsed,
  ] = useState<boolean>(false)
  const departmentsRef = useRef<Department[]>([])

  const fetchJobs = useCallback(async () => {
    try {
      const response: Response = await fetch(DepartmentsURL)
      const fetchedDepartments: FetchedDepartments = await response.json()
      departmentsRef.current = fetchedDepartments.departments
    } catch (err) {
      console.error('Error fetching jobs')
    }
  }, [])

  const { error, load } = useLoads('fetchJobs', fetchJobs as any, {
    defer: true,
  })

  useEffect(() => {
    if (enableJobs) {
      load()
    }
  }, [0])

  useEffect(() => {
    const jobs = filterJobs()
    parseLocationsAndTeams(jobs)
  }, [departmentsRef.current, cityFilter, teamFilter])

  const filterJobs = () => {
    const jobs: FormattedJob[] = []
    const usedDepartments: any = {}
    const usedJobs: any = {}

    departmentsRef.current?.forEach((department: Department) => {
      if (
        !usedDepartments[department.id] &&
        (department.name === teamFilter || teamFilter === 'all')
      ) {
        usedDepartments[department.id] = true

        department.jobs?.forEach((job: Job) => {
          const splittedLocation: string[] = job.location.name?.split(',');

          if (splittedLocation?.length > 2) {
            //Remove country name if there is
            splittedLocation.pop()
          }

          if (splittedLocation?.length > 1) {
            splittedLocation[1] =
              namesToAbbreviations[splittedLocation[1].trim()]
          }

          if (
            !usedJobs[job.id] &&
            ((splittedLocation?.length === 1 &&
              splittedLocation[0].trim() === cityFilter) ||
              (splittedLocation?.length > 1 &&
                splittedLocation?.join(', ') === cityFilter) ||
              cityFilter === 'all')
          ) {
            usedJobs[job.id] = true
            const usedLocations: any = {}

            usedLocations[job.location.name] = true

            const formattedJob = {
              ...job,
              location: {
                name: [splittedLocation?.join(', ')],
                link: [job.absolute_url],
              },
            }

            department.jobs?.forEach((job2: Job) => {
              if (
                job.title.trim() === job2.title.trim() &&
                !usedJobs[job2.id]
              ) {
                if (!usedLocations[job2.location.name]) {
                  usedLocations[job2.location.name] = true
                  usedJobs[job2.id] = true

                  const splittedLocation: string[] = job2.location.name?.split(
                    ','
                  )

                  if (splittedLocation?.length > 2) {
                    //Remove country name if there is
                    splittedLocation.pop()
                  }

                  if (splittedLocation?.length > 1) {
                    splittedLocation[1] =
                      namesToAbbreviations[splittedLocation[1].trim()]
                  }
                  formattedJob.location.name?.push(splittedLocation?.join(', '))
                  formattedJob.location.link.push(job2.absolute_url)
                }
              }
            })

            jobs.push(formattedJob)
          }
        })
      }
    })
    setJobsList(jobs)
    return jobs
  }

  const parseLocationsAndTeams = (jobs: FormattedJob[]) => {
    if (jobs?.length > 0 && !haveTeamsAndLocationsBeenParsed) {
      const teams: SingleSelectorProps[] = [
        { id: 'all', title: 'All Teams', available: true, fullName: 'all' },
      ]
      const usedTeams: any = {}

      departmentsRef.current?.forEach((department: Department) => {
        if (department.jobs?.length === 0) {
          usedTeams[department.id] = true
          return
        }

        if (!usedTeams[department.id]) {
          usedTeams[department.id] = true
          const team: SingleSelectorProps = {
            id: department.id,
            title: department.name,
            available: true,
            children: [],
            fullName: department.name,
            child_ids: [...department.child_ids],
          }

          const processChildDepartments = (team: SingleSelectorProps) => {
            if (!team.child_ids?.length) {
              return team
            }

            team.child_ids?.forEach((id: number | string, index: number) => {
              if (!usedTeams[id]) {
                usedTeams[id] = true
                const childTeam:
                  | Department
                  | undefined = departmentsRef.current.find(
                  (team: Department) => team.id === id
                )

                if (!childTeam) {
                  return
                } else if (childTeam.jobs?.length === 0) {
                  usedTeams[id] = true
                  return
                }

                const formattedChildTeam: SingleSelectorProps = {
                  id: childTeam.id,
                  title: childTeam.name,
                  available: true,
                  children: [],
                  fullName: childTeam.name,
                  child_ids: [...childTeam.child_ids],
                }

                const processedChildDepartment = processChildDepartments(
                  formattedChildTeam
                )
                if (processedChildDepartment.id !== formattedChildTeam.id) {
                  formattedChildTeam?.children?.push(processedChildDepartment)
                }
                team?.children?.push(formattedChildTeam)
              }
            })

            return team
          }
          const processedDepartments = processChildDepartments(team)

          const parentTeam = departmentsRef.current.find(
            (team: Department) => team.id === department.parent_id
          )

          if (department.parent_id && parentTeam?.jobs?.length! > 0) {
            if (usedTeams[department.parent_id]) {
              for (let i = 0; i < teams?.length; i++) {
                if (department.parent_id === teams[i].id) {
                  teams[i]?.children?.push(processedDepartments)
                  break
                }
              }
            } else {
              usedTeams[department.parent_id] = true
              const formattedParentTeam: SingleSelectorProps = {
                id: parentTeam!.id,
                title: parentTeam!.name,
                available: true,
                children: [processedDepartments],
                fullName: parentTeam!.name,
                child_ids: [...parentTeam!.child_ids],
              }
              teams.push(formattedParentTeam)
            }
          } else {
            teams.push(processedDepartments)
          }
        }
      })

      const locations: SingleSelectorProps[] = [
        { id: 'all', title: 'All Locations', available: true, fullName: 'all' },
      ]
      const usedLocations: any = {}

      let remote = null

      jobs?.forEach((job: FormattedJob) => {
        job.location.name?.forEach((city: string) => {
          if (!usedLocations[city]) {
            usedLocations[city] = true
            const splittedLocation: string[] = city?.split(',')

            const location: SingleSelectorProps = {
              id: splittedLocation?.join('').trim(),
              title: splittedLocation?.[1]
                ? splittedLocation?.[0] + ', ' + splittedLocation[1]
                : splittedLocation?.[0] || '',
              available: true,
              fullName: city,
            }
            if (locations[locations?.length - 1].title === 'All Locations') {
              locations.push(location)
            } else if (location.title === 'Remote') {
              remote = location
            } else if (
              toAlphabetically(
                location.title,
                locations[locations?.length - 1].title
              )
            ) {
              const temp = locations[locations?.length - 1]
              locations[locations?.length - 1] = location
              locations.push(temp)
            } else {
              locations.push(location)
            }
          }
        })
      })

      if (remote) {
        locations.push(remote)
      }

      setHaveTeamsAndLocationsBeenParsed(true)
      setTeams(teams)
      setLocations(locations)
    }
  }

  return (
    <div className="pys mb20 rel bcw cb">
      <div className="container--l outer mxa mb20 px1 pb1 al p x">
        {title && <h2>{title}</h2>}
        {jobList && teams?.length > 0 && locations?.length > 0 && (
          <React.Fragment>
            <div className="f fw jcs aic my1 py1">
              <SingleSelector
                options={teams}
                handleSelection={(e: SingleSelectorProps) => {
                  setTeamFilter(e.fullName)
                }}
              />
              <SingleSelector
                options={locations}
                handleSelection={(e: SingleSelectorProps) =>
                  setCityFilter(e.fullName)
                }
              />
            </div>
          </React.Fragment>
        )}
        {error && <span>Problem loading Jobs from Greenhouse</span>}
        {jobList &&
          jobList.map((job, i) => (
            <div className={cx('job__single py1 js-single x aic')} key={i}>
              <span className="f x jcb aic">
                <div className="f x jcs aic job__single-main">
                  <div className={`f fw x`}>
                    <span className="bold job__single-title">{job.title}</span>
                    {job.location?.name?.map((name: string, index: number) => (
                      <a
                        key={index}
                        href={job.location.link[index]}
                        target="_blank"
                        className="job__single-entry"
                      >
                        {`${name}${
                          index === job.location.name?.length - 1 ? '' : '; '
                        }`}
                      </a>
                    ))}
                  </div>
                </div>
              </span>
            </div>
          ))}
        {!jobList?.length && (
          <div className="x container--m pym">
            <h3 className="karla">
              Sorry! No jobs match your search.
              <br />
              Try filtering again!
            </h3>
          </div>
        )}
      </div>
    </div>
  )
}
