import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { EditorialSerializer } from 'src/utils/editorialSerializer'

export interface EditorialTextProps {
  data: {
    editorialText: any[]
    backgroundColor: string
    spacing: string
    containerSize: string
  }
}

export const EditorialText = ({ data }: EditorialTextProps) => {
  const { editorialText, spacing, backgroundColor } = data

  return (
    <div
      className={cx(backgroundColor, spacing, {
        cw: backgroundColor === 'bcb',
      })}
    >
      <div className="editorial__core">
        <BlockContent
          blocks={editorialText}
          serializers={EditorialSerializer}
        />
      </div>
    </div>
  )
}
