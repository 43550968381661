import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'

import BlockContent from '@sanity/block-content-to-react'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

export interface FeaturedTopicProps {
  data: {
    text?: any[]
    backgroundColor?: string
    topics: any[]
  }
}

export const FeaturedTopic = ({ data }: FeaturedTopicProps) => {
  const { text, topics, backgroundColor } = data

  return (
    <div
      className={cx(
        'rel outer article__topic pym p1 container--l mxa',
        backgroundColor,
        {
          cw: backgroundColor === 'bcb',
          'cb bctran': backgroundColor === undefined,
        }
      )}
    >
      <div className="container--l mt50 mb50  mxa pym al pyl75 x f fw rel jcb ais">
        {backgroundColor === 'bcb' ? (
          <div
            className="featured__squiggle abs top left x"
            style={{ backgroundImage: 'url(/squiggle.png)' }}
          />
        ) : (
          <div
            className="featured__squiggle abs top left x"
            style={{ backgroundImage: 'url(/squiggle-black.png)' }}
          />
        )}
        <div className="col-m-5 x">
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
        <div className="col-m-5 article__topic-list mt1 x">
          {topics.map(article => {
            const {
              content: {
                main: { title, articleType, cardImage, slug, image },
              },
            } = article
            return (
              <div key={article._id} className={cx('x article__topic-single')}>
                <Link to={`/article/${slug.current}`} className="f jcs x aic">
                  <div className="article__topic-image rel col-s-2 x">
                    {cardImage ? (
                      <Image
                        className="abs y x"
                        imageId={cardImage.asset._id}
                        raw={cardImage}
                        alt={title}
                        size={200}
                      />
                    ) : (
                      <Image
                        className="x y abs top left"
                        imageId={image.asset._id}
                        alt={title}
                      />
                    )}
                  </div>
                  <div className="article__topic-text f jcb fw px1 col-s-8 x">
                    <div>
                      {articleType && (
                        <h5 className="caps mono m0 pb05">
                          {articleType.title}
                        </h5>
                      )}
                      <h4 className="m0 py0 sm21">{title}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        {backgroundColor === 'bcb' ? (
          <div
            className="featured__squiggle abs bottom left x"
            style={{ backgroundImage: 'url(/squiggle.png)' }}
          />
        ) : (
          <div
            className="featured__squiggle abs bottom left x"
            style={{ backgroundImage: 'url(/squiggle-black.png)' }}
          />
        )}
      </div>
    </div>
  )
}

export default FeaturedTopic
