import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'

export interface InlineCTAProps {
  data: {
    text: any[]
    backgroundColor?: string
  }
}

export const InlineCTA = ({ data }: InlineCTAProps) => {
  const { text, backgroundColor } = data

  return (
    <div className="py1 py2 my1">
      <div className="x ">
        <div
          className={cx(
            'pys container--l rel oh outer mxa py1 pys cta__inline rel',
            {
              cw:
                backgroundColor === 'bcb' ||
                backgroundColor === 'bcblue' ||
                backgroundColor === 'bcraspberry' ||
                backgroundColor === 'bcp',
              'cw ': backgroundColor === undefined,
            }
          )}
        >
          <div
            className={cx(
              'container--l outer mxa br p1 pym oh rel al p x',
              backgroundColor,
              {
                'cw bcb ': backgroundColor === undefined,
              }
            )}
          >
            <div className="cta__inner rel z1 my1">
              <BlockContent blocks={text} serializers={Serializer} />
            </div>
            <div className="cta__line abs right bottom">
              <svg width="187" height="324" viewBox="0 0 187 324" fill="none">
                <path
                  d="M132.789 1.4155C133.246 4.27925 72.1777 10.9621 105.621 68.3599C131.998 113.502 -15.0532 73.7195 4.22819 198.339C23.5096 322.958 185.485 299.245 140.164 358.984C94.8433 418.724 232.393 564.989 334.273 341.954"
                  stroke="currentColor"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
