const typeformEmbed = require('@typeform/embed')
import React, { useEffect, useRef } from 'react'
export interface TypeFormProps {
  data: {
    url: string
    width: string
    height: string
    background: string
  }
}

export const TypeForm = ({ data }: TypeFormProps) => {
  const elementRef = useRef(null)
  useEffect(() => {
    if (elementRef.current && data.url) {
      typeformEmbed.makeWidget(elementRef.current, data.url, {
        hidefooter: true,
        hideHeaders: true,
      })
    }
  }, [data.url])
  return (
    <div
      style={{
        backgroundColor: data.background,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        ref={elementRef}
        style={{ width: data.width, height: data.height }}
      />
    </div>
  )
}
export default TypeForm
