import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import vsbl from 'vsbl'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { StandardTextProps } from 'src/components/global/interfaces/StandardText'

export const StandardText = ({ data }: { data: StandardTextProps }) => {
  const { text, backgroundColor, spacing, containerSize, deepLinkId } = data
  const dom = useRef<HTMLDivElement>(null)
  const [className, setClass] = useState('')
  useEffect(() => {
    const { current } = dom
    const vs = vsbl(current)(() => {
      setClass('is-visible')
    })
    vs.update()
  }, [0])

  return (
    <div
      id={deepLinkId}
      ref={dom}
      className={cx(backgroundColor, className, spacing, {
        cw: backgroundColor === 'bcb',
      })}
    >
      <div className="container--l outer opacity slide-up d2 animate mxa standard__text py2 p1 al x">
        <div className={containerSize}>
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
      </div>
    </div>
  )
}
