import React, {useCallback, useEffect, useRef, useState} from "react";
import cx from "classnames";
import {useEmblaCarousel} from "embla-carousel/react";
import {Arrow} from "../svgs";
import StarIcon from "../../Icons/starIcon";

export interface TestimonialProps {
  data: {
    testimonials: {
        Title: string;
        Content: string
    }[];
    backgroundColor: string
  }
}

export const useRecursiveTimeout = (callback, delay) => {
    const [isRunning, setIsRunning] = useState(false)
    const stop = useCallback(() => setIsRunning(false), [setIsRunning])
    const play = useCallback(() => setIsRunning(true), [setIsRunning])
    const savedCallback = useRef(callback)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (!isRunning) return
        let id = 0

        const tick = () => {
            if (!isRunning) return clearTimeout(id)
            savedCallback.current()
            requestAnimationFrame(() => (id = setTimeout(tick, delay)))
        }
        requestAnimationFrame(() => (id = setTimeout(tick, delay)))

        return () => {
            if (id) clearTimeout(id)
            stop()
        }
    }, [isRunning, delay, stop])

    return { play, stop }
}

export const Testimonials = ({ data }: TestimonialProps) => {
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

    const { testimonials, backgroundColor } = data;


    const [slide, setSlide] = useState(1)

    const [EmblaCarouselReact, embla] = useEmblaCarousel({
        containScroll: true,
        align: 'start',
        slidesToScroll: 1,
    })

    const autoplay = useCallback(() => {
        return
        if (!embla) return
        if (embla.canScrollNext()) {
            embla.scrollNext()
        } else {
            embla.scrollTo(0)
        }
    }, [embla])

    const { play, stop } = useRecursiveTimeout(autoplay, 5000)

    const scrollNext = useCallback(() => {
        if (!embla) return
        embla.scrollNext()
        stop()
    }, [embla, stop])

    const scrollPrev = useCallback(() => {
        if (!embla) return
        embla.scrollPrev()
        stop()
    }, [embla, stop])

    const scrollTo = useCallback(
        index => {
            if (!embla) return
            embla.scrollTo(index)
            stop()
        },
        [embla, stop]
    )

    const onSelect = useCallback(() => {
        if (!embla) return
        setPrevBtnEnabled(embla.canScrollPrev())
        setNextBtnEnabled(embla.canScrollNext())
    }, [embla])

    useEffect(() => {
        if (!embla) return
        onSelect()
        embla.on('select', onSelect)
        embla.on('pointerDown', stop)
    }, [embla, onSelect, stop])

    useEffect(() => {
        play()
    }, [play])

    useEffect(() => {
        if (embla) {
            embla.on('select', () => {
                setSlide(embla.selectedScrollSnap() + 1)
            })
        }
    }, [embla])

    return (
        <div
            className={cx('py1 pym', `bg-${backgroundColor}`)}
        >
            {testimonials && testimonials.length >= 1 && (
                <div
                    className={cx(
                        'container--l x mxa outer f fw jcb aist al'
                    )}
                >
                    <div
                        className={cx('fw  x')}
                    >
                        <div className="x">
                            <EmblaCarouselReact className="y">
                                <div className="x y f" style={{ display: 'flex' }}>
                                    {testimonials.map((item, index) => {
                                        return (
                                            <div key={index} className="x tm-slide" style={{ padding: '0 20px', flex: '1', display: 'flex' }}>
                                                <div className="y rel x bg-primary-3 tm-itemWrapper">
                                                   <div>
                                                      <span className='tm-itemTitle'>
                                                           {item?.Title}
                                                      </span>
                                                   </div>
                                                    <div className='tm-starsWrapper'>
                                                        <StarIcon />
                                                        <StarIcon />
                                                        <StarIcon />
                                                        <StarIcon />
                                                        <StarIcon />
                                                    </div>
                                                    <div>
                                                        <span className='tm-itemContent'>
                                                            "{item?.Content}"
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </EmblaCarouselReact>
                        </div>
                        <div
                            style={{ padding: '0 20px' }}
                            className={cx('rel x f fw aic col-m-6')}
                        >
                            <div
                                className={cx('py1 bottom f jcs aic')}
                            >
                                <button
                                    aria-label="carousel button"
                                    className={cx('no-style pointer')}
                                    onClick={() => scrollPrev()}
                                >
                                    <Arrow />
                                </button>
                                <div className="m1 carousel__dots f jcc aic">
                                    {Array.from(Array(Math.ceil(testimonials.length)).keys())?.map((item, i) => (
                                        <span
                                            onClick={() => scrollTo(i)}
                                            key={i}
                                            style={{ display: 'block' }}
                                            className={cx('dot block', {
                                                'is-active': slide - 1 === i,
                                            })}
                                        />
                                    ))}
                                </div>
                                <button
                                    aria-label="scroll next"
                                    className={cx('no-style arrow__rotate')}
                                    onClick={() => scrollNext()}
                                >
                                    <Arrow />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Testimonials