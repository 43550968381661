import React from 'react'

import Loadable from 'react-loadable'

import { TypeForm, TypeFormProps } from 'src/components/global/typeForm'
import { Hero, HeroProps } from 'src/components/global/Hero'
import { ImageAndTextProps } from 'src/components/global/imageAndText'
import {
  StandardText,
  StandardTextProps,
} from 'src/components/global/standardText'
import {
  EditorialText,
  EditorialTextProps,
} from 'src/components/global/editorialText'
import { CarouselProps } from 'src/components/global/carousel'
import { TestimonialCarouselProps } from 'src/components/global/testimonialCarousel'
import { Shape, ShapeProps } from 'src/components/global/shape'
import { CTA, CTAProps } from 'src/components/global/cta'
import { Jobs, JobsProps } from 'src/components/global/jobs'
import { Spacer, SpacerProps } from 'src/components/global/spacer'
import { FaqsProps } from 'src/components/global/faqs'
import { PricingBlockProps } from 'src/components/global/pricingBlock'
import { TextGridProps } from 'src/components/global/textGrid'
import { TextCarouselProps } from 'src/components/global/textCarousel'
import { VideoProps } from 'src/components/global/video'
import { JoinWidgetProps } from 'src/components/global/joinWidget'
import { Note, NoteProps } from 'src/components/global/note'
import { AppointmentsModuleProps } from 'src/components/global/appointmentsModule'
import { EventsModuleProps } from 'src/components/global/eventsModule'
import { FurtherReadingProps } from 'src/components/editorial/furtherReading'
import { MembershipCardProps } from 'src/components/global/membershipCard'
import { ImageModuleProps } from 'src/components/global/imageModule'
import {
  TableModule,
  TableModuleProps,
} from 'src/components/global/tableModule'

import { InsuranceModuleProps } from 'src/components/global/insuranceModule'
import { FeaturedArticlesProps } from 'src/components/editorial/featuredArticles'
import { EditorialImageAndTextProps } from 'src/components/editorial/editorialImageAndText'
import { EditorialGridProps } from 'src/components/global/editorialGrid'
import { ExperimentProps } from 'src/components/global/experiment'
import { FeaturedTopicProps } from 'src/components/editorial/featuredTopic'
import { ImageCarouselProps } from 'src/components/global/imageCarousel'
import {
  LocationsList,
  LocationsListProps,
} from 'src/components/locations/locationsList'
import { TeamGridProps } from 'src/components/team/teamGrid'
import EmailCapture from './global/emailCapture'
import { EmailCaptureProps } from './global/interfaces/EmailCapture'
import LocationCarousel, {
  LocationCarouselProps,
} from './locations/locationCarousel'
import Testimonials, { TestimonialProps } from './global/Testimonials'
import { LeadForm, LeadFormProps } from './global/leadForm'
import FullBleedHero, { FullBleedHeroProps } from './global/FullBleedHero'

export const Modules = ({
  reactModule,
  type,
}: {
  type: string
  reactModule: any
}) => {
  switch (type) {
    case 'textGrid':
      const TextGrid = Loadable({
        loader: () => import('src/components/global/textGrid'),
        loading: () => <span>Loading</span>,
      })
      return <TextGrid data={reactModule as TextGridProps['data']} />
    case 'typeForm':
      const Typeform = Loadable({
        loader: () => import('src/components/global/typeForm'),
        loading: () => <span>Loading</span>,
      })
      return <TypeForm data={reactModule as TypeFormProps['data']} />
    case 'editorialGrid':
      const EditorialGrid = Loadable({
        loader: () => import('src/components/global/editorialGrid'),
        loading: () => <span>Loading</span>,
      })
      return <EditorialGrid data={reactModule as EditorialGridProps['data']} />
    case 'appointmentsModule':
      const AppointmentsModule = Loadable({
        loader: () => import('src/components/global/appointmentsModule'),
        loading: () => <span>Loading</span>,
      })
      return (
        <AppointmentsModule
          data={reactModule as AppointmentsModuleProps['data']}
        />
      )
    case 'sideBySide':
      const SideBySideModule = Loadable({
        loader: () => import('src/components/global/sideBySide'),
        loading: () => <span>Loading</span>,
      })
      return (
        <SideBySideModule
          data={reactModule as AppointmentsModuleProps['data']}
        />
      )
    case 'textCarousel':
      const TextCarousel = Loadable({
        loader: () => import('src/components/global/textCarousel'),
        loading: () => <span>Loading</span>,
      })
      return <TextCarousel data={reactModule as TextCarouselProps['data']} />
    case 'eventsModule':
      const EventsModule = Loadable({
        loader: () => import('src/components/global/eventsModule'),
        loading: () => <span>Loading</span>,
      })
      return <EventsModule data={reactModule as EventsModuleProps['data']} />
    case 'experiment':
      const Experiment = Loadable({
        loader: () => import('src/components/global/experiment'),
        loading: () => <span>Loading</span>,
      })
      return <Experiment data={reactModule as ExperimentProps['data']} />
    case 'spacer':
      return <Spacer data={reactModule as SpacerProps['data']} />
    case 'furtherReading':
      const FurtherReading = Loadable({
        loader: () => import('src/components/editorial/furtherReading'),
        loading: () => <span>Loading</span>,
      })
      return (
        <FurtherReading data={reactModule as FurtherReadingProps['data']} />
      )
    case 'joinWidget':
      const JoinWidget = Loadable({
        loader: () => import('src/components/global/joinWidget'),
        loading: () => <span>Loading</span>,
      })
      return <JoinWidget data={reactModule as JoinWidgetProps['data']} />
    case 'membershipCard':
      const MembershipCard = Loadable({
        loader: () => import('src/components/global/membershipCard'),
        loading: () => <span>Loading</span>,
      })
      return (
        <MembershipCard data={reactModule as MembershipCardProps['data']} />
      )
    case 'note':
      return <Note data={reactModule as NoteProps['data']} />
    case 'editorialImageAndText':
      const EditorialImageAndText = Loadable({
        loader: () => import('src/components/editorial/editorialImageAndText'),
        loading: () => <span>Loading</span>,
      })
      return (
        <EditorialImageAndText
          data={reactModule as EditorialImageAndTextProps['data']}
        />
      )
    case 'tableModule':
      return <TableModule data={reactModule as TableModuleProps['data']} />
    case 'editorialText':
      return <EditorialText data={reactModule as EditorialTextProps['data']} />
    case 'testimonialCarousel':
      const TestimonialCarousel = Loadable({
        loader: () => import('src/components/global/testimonialCarousel'),
        loading: () => <span>Loading</span>,
      })
      return (
        <TestimonialCarousel
          data={reactModule as TestimonialCarouselProps['data']}
        />
      )
    case 'insuranceModule':
      const InsuranceModule = Loadable({
        loader: () => import('src/components/global/insuranceModule'),
        loading: () => <span>Loading</span>,
      })
      return (
        <InsuranceModule data={reactModule as InsuranceModuleProps['data']} />
      )
    case 'imageAndText':
      const ImageAndText = Loadable({
        loader: () => import('src/components/global/imageAndText'),
        loading: () => <span>Loading</span>,
      })
      return <ImageAndText data={reactModule as ImageAndTextProps['data']} />
    case 'standardText':
      return <StandardText data={reactModule as StandardTextProps['data']} />
    case 'carousel':
      const Carousel = Loadable({
        loader: () => import('src/components/global/carousel'),
        loading: () => <span>Loading</span>,
      })
      return <Carousel data={reactModule as CarouselProps['data']} />
    case 'cta':
      return <CTA data={reactModule as CTAProps['data']} />
    case 'locationsList':
      return <LocationsList data={reactModule as LocationsListProps['data']} />
    case 'featuredTopic':
      const FeaturedTopic = Loadable({
        loader: () => import('src/components/editorial/featuredTopic'),
        loading: () => <span>Loading</span>,
      })
      return <FeaturedTopic data={reactModule as FeaturedTopicProps['data']} />
    case 'featuredArticles':
      const FeaturedArticles = Loadable({
        loader: () => import('src/components/editorial/featuredArticles'),
        loading: () => <span>Loading</span>,
      })
      return (
        <FeaturedArticles data={reactModule as FeaturedArticlesProps['data']} />
      )
    case 'faqModule':
      const Faqs = Loadable({
        loader: () => import('src/components/global/faqs'),
        loading: () => <span>Loading</span>,
      })
      return <Faqs data={reactModule as FaqsProps['data']} />
    case 'video':
      const Video = Loadable({
        loader: () => import('src/components/global/video'),
        loading: () => <span>Loading</span>,
      })
      return <Video data={reactModule as VideoProps['data']} />
    case 'imageCarousel':
      const ImageCarousel = Loadable({
        loader: () => import('src/components/global/imageCarousel'),
        loading: () => <span>Loading</span>,
      })
      return <ImageCarousel data={reactModule as ImageCarouselProps['data']} />
    case 'jobs':
      return <Jobs data={reactModule as JobsProps['data']} />
    case 'teamGrid':
      const TeamGrid = Loadable({
        loader: () => import('src/components/team/teamGrid'),
        loading: () => <span>Loading</span>,
      })
      return <TeamGrid data={reactModule as TeamGridProps['data']} />
    case 'imageModule':
      const ImageModule = Loadable({
        loader: () => import('src/components/global/imageModule'),
        loading: () => <span>Loading</span>,
      })
      return <ImageModule data={reactModule as ImageModuleProps['data']} />

    case 'pricingBlock':
      const PricingBlock = Loadable({
        loader: () => import('src/components/global/pricingBlock'),
        loading: () => <span>Loading</span>,
      })
      return <PricingBlock data={reactModule as PricingBlockProps['data']} />
    case 'shape':
      return <Shape data={reactModule as ShapeProps['data']} />
    case 'hero':
      return <Hero data={reactModule as HeroProps['data']} />
    case 'fullBleedHero':
      return <FullBleedHero data={reactModule as FullBleedHeroProps['data']} />
    case 'leadForm':
      return <LeadForm data={reactModule as LeadFormProps['data']} />
    case 'testimonials':
      return <Testimonials data={reactModule as TestimonialProps['data']} />
    case 'emailCapture':
      return <EmailCapture data={reactModule as { data: EmailCaptureProps }} />
    case 'cardCarousel':
      return (
        <LocationCarousel
          data={reactModule as { data: LocationCarouselProps }}
        />
      )
    default:
      return <h2 className="hide">{type} (coming soon :))</h2>
  }
}
