import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import classes from './singleSelector.module.css'

import { SmallCaret } from 'src/components/svgs'
import { SingleSelectorProps } from 'src/interfaces/SingleSelector'

export const SingleSelector = ({
  options,
  handleSelection,
}: {
  options: SingleSelectorProps[]
  handleSelection: any
}) => {
  const [activeVariant, setActiveVariant] = useState(options[0])
  const [showDropDown, setShowDropDown] = useState<Boolean>(false)
  const [randomId, setRandomId] = useState<number>()

  const handleChange = (e: SingleSelectorProps) => {
    setActiveVariant(e)

    for (let i = 0; i < options.length; i++) {
      const processChildOptions = (option: SingleSelectorProps) => {
        if (option.id == e.id) {
          handleSelection(option)
        } else if (!option.children || !option.children.length) {
          return
        } else {
          for (let j = 0; j < option.children.length; j++) {
            processChildOptions(option.children[j])
          }
        }
      }

      processChildOptions(options[i])
    }
    setShowDropDown(false)
  }

  useEffect(() => {
    if (!sessionStorage.getItem('singleSelectorIds')) {
      sessionStorage.setItem('singleSelectorIds', JSON.stringify({}))
    }

    const singleSelectorIds = JSON.parse(
      sessionStorage.getItem('singleSelectorIds') as string
    )

    const compareId = (id: number) => {
      if (!singleSelectorIds[id]) {
        singleSelectorIds[id] = true
        setRandomId(id)
      } else {
        compareId(Math.floor(Math.random() * 100) + 1)
      }
    }
    compareId(Math.floor(Math.random() * 100) + 1)
    sessionStorage.setItem(
      'singleSelectorIds',
      JSON.stringify(singleSelectorIds)
    )

    return () => {
      if (randomId) {
        delete singleSelectorIds[randomId]
        sessionStorage.setItem(
          'singleSelectorIds',
          JSON.stringify(singleSelectorIds)
        )
      }
    }
  }, [])

  const closeDropDown = (e: Event) => {
    if ((e.target as HTMLElement).classList.contains(`dropdown${randomId}`)) {
      return
    }

    setShowDropDown(false)
  }

  useEffect(() => {
    document.addEventListener('click', closeDropDown)
    return () => {
      document.removeEventListener('click', closeDropDown)
    }
  }, [randomId])

  const parseSelectors = (options: SingleSelectorProps[], cycle = 0) => {
    return options?.map((option: SingleSelectorProps) => {
      return (
        <React.Fragment key={option.id}>
          <div
            className={cx(`${classes.option}`, {
              active: option.id === activeVariant.id,
            })}
            onClick={() => handleChange(option)}
            title={option.title}
            style={{ paddingLeft: 20 + cycle * 10 + 'px' }}
          >
            {option.title}
          </div>

          {option?.children?.length! > 0 &&
            parseSelectors(option.children!, cycle + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <div
      className={cx(
        `dropdown${randomId} select__single y caps mr1 rel x f jcc aic`
      )}
    >
      <button
        onClick={() => setShowDropDown(!showDropDown)}
        className={`dropdown${randomId} ${classes.dropdownRoot}`}
      >
        <span title={activeVariant.title} className={classes.title}>
          {activeVariant.title}
        </span>
        <SmallCaret
          className={`${classes.arrow}  ${
            showDropDown ? classes.arrowRotate : ''
          }`}
        />
      </button>
      <div
        className={`${classes.dropdownModal} ${
          showDropDown ? classes.show : ''
        }`}
      >
        {parseSelectors(options)}
      </div>
    </div>
  )
}
