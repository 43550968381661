import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface NoteProps {
  data: {
    text: any[]
    backgroundColor: string
    spacing: string
    containerSize: string
    image: {
      asset: {
        _id: string
      }
    }
  }
}

export const Note = ({ data }: NoteProps) => {
  const { text, backgroundColor, spacing, image, containerSize } = data

  return (
    <div
      className={cx(backgroundColor, spacing, {
        cw: backgroundColor === 'bcb' || backgroundColor === 'bcraspberry',
      })}
    >
      <div className="container--l outer mxa py1 p1 al x">
        <div className={cx('f fw jcs aic container--m mxa', containerSize)}>
          <div className="note__icon x col-m-4">
            <Image className="x" imageId={image.asset._id} alt="note image" />
          </div>
          <div className="x col-m-6 note__body">
            <BlockContent blocks={text} serializers={Serializer} />
          </div>
        </div>
      </div>
    </div>
  )
}
