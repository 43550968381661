const toAlphabetically = (a: string, b: string) => {
  a = a.toLowerCase()
  b = b.toLowerCase()
  let position = 0

  while (a[position] === b[position]) {
    if (!a[position] && !b[position]) {
      return 0
    }

    if (!a[position]) {
      return 1
    }
    if (!b[position]) {
      return -1
    }
    position = position + 1
  }

  return a[position] < b[position]
}

export default toAlphabetically
