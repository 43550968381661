import React, { ReactElement, useRef } from 'react'
import cx from 'classnames'
import vsbl from 'vsbl'
import './styles.css'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface FullBleedHeroProps {
  data: {
    text: any[]
    backgroundColor?: string
    containerSize?: string
    spacing?: string
    bgImage: { asset: { _id: string; _ref?: string } }
  }
}

export const FullBleedHero = ({ data }: FullBleedHeroProps): ReactElement => {
  const {
    text,
    backgroundColor,
    containerSize,
    spacing,
    bgImage,
  } = data
  const dom = useRef()

  const imageIdentifier = bgImage?.asset?._id || bgImage?.asset?._ref

  return (
    <div
      ref={dom}
      className={cx('rel', 'is-visible', backgroundColor, spacing, {
        cw: backgroundColor === 'bcb',
        pyl: spacing === undefined,
        'cw bcb': backgroundColor === undefined,
      })}
    >
      {imageIdentifier && (
        <Image
        className='bg-image'
          imageId={imageIdentifier}
          alt={''}
          raw={bgImage}
          lazy
        />
      )}
      <div className="container--l outer mxa hero__inner f jcs ais py2 p1 al x rel">
        <div
          className={cx('x animate opacity slide-up d2 col-m-7 col-xs-24', {
            containerSize,
          })}
        >
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
        
      </div>
    </div>
  )
}

export default FullBleedHero
