import React, { useState } from 'react'
import astrochimp from 'astrochimp'

import cx from 'classnames'

export interface NewsletterBlockProps {
  data: {
    cta?: string
    placeholder?: string
  }
}

export const NewsletterBlock = ({ data }: NewsletterBlockProps) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { cta, placeholder, listId = 'a3b0145bae' } = data
  const handleSubmit = e => {
    e.preventDefault()
    setError(false)
    const form = e.currentTarget
    const { email } = form.elements
    astrochimp(
      `https://asktia.us14.list-manage.com/subscribe/post?u=5df6dfff95719d22ed3b3940a&amp;id=${listId}`,
      {
        EMAIL: email.value,
      },
      (err, data) => {
        if (!err) {
          setSuccess(true)
        } else {
          setError(true)
        }
      }
    )
  }

  return (
    <div className="f fw mt1 pt1 newsletter--block jcs aic">
      {!success ? (
        <React.Fragment>
          <form className="f aist x" onSubmit={e => handleSubmit(e)}>
            <input
              type="email"
              name="email"
              className="ss15 karla py1"
              aria-label="email"
              placeholder={placeholder ? placeholder : 'Email*'}
              required
            />
            <button
              type="submit"
              className="button button--tertiary-4 f jcc aic rel ss15"
            >
              <span className="rel karla bold z1">
                {cta ? cta : 'Sign up!'}
              </span>
            </button>
          </form>
          <div>
            {error && (
              <span className="block mt05">
                You appear to already be signed up!
              </span>
            )}
          </div>
        </React.Fragment>
      ) : (
        <p>We've got it!</p>
      )}
    </div>
  )
}
