import React from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

export interface InlineImageAndTextProps {
  data: {
    text: any[]
    backgroundColor: string
    image: {
      asset: {
        _id: string
      }
    }
    alignment?: string
    spacing: string
    style?: string
  }
}

export const InlineImageAndText = ({ data }: InlineImageAndTextProps) => {
  const { text, backgroundColor, image, alignment, spacing, style } = data
  return (
    <div className={cx('my1', spacing)}>
      <div className="container--l outer mxa pys py2">
        <div
          className={cx(
            'container--l x outer mxa pys py2 br',
            backgroundColor,
            {
              cw: backgroundColor === 'bcb',
            }
          )}
        >
          <div className="container--l x mxa py2 al x">
            <div
              className={cx('f jcb x fw ', alignment, style, {
                jcs: style === 'card' && alignment !== 'jcrr',
                aic: style !== 'card',
                aist: style === 'card',
              })}
            >
              <div className="x col-m-3 rel image__text-img">
                {image && <Image imageId={image.asset._id} alt="" />}
              </div>
              <div className="x col-m-66 pb1">
                <BlockContent blocks={text} serializers={Serializer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
