import { resolve } from 'bluebird'
import Cookies from 'js-cookie'

export type Methods = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'OPTIONS' | 'DELETE'

export interface IOptions<T> {
  options?: RequestInit
  errorMsg?: string

  customErrorHandler?: (res: Response) => Error
  customReturn?: (res: Response) => Promise<T>
}

export const fetcher = async <T = any>(
  url: string,
  body?: any,
  method?: Methods,
  extra?: IOptions<T>,
  jsonContentType = true
): Promise<T> => {
  const metaCsrf = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content')
  const headers: any = {
    'X-Xsrf-Token': metaCsrf || Cookies.get('xsrf-token') || '',
    ...extra?.options?.headers,
  }

  if (jsonContentType) {
    headers['Content-Type'] = 'application/json'
  }
  const res = await fetch(url, {
    method,
    body,
    credentials: 'same-origin',
    ...extra?.options,
    headers,
  })

  if (!res.ok) {
    const exception: any = extra?.customErrorHandler
      ? extra.customErrorHandler(res)
      : new Error(extra?.errorMsg || 'Server returned not-ok')

    exception['response'] = res
    throw exception
  }

  if (extra?.customReturn) {
    return await extra?.customReturn(res)
  }

  return res
    .json()
    .then(json => resolve(json))
    .catch(err => {
      console.warn('Failed to parse response body due the error', err.message)
      return resolve()
    })
}
