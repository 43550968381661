import React from 'react'
import cx from 'classnames'

import { ShapeOne, ShapeTwo, ShapeThree, ShapeFour } from 'src/components/svgs'

const selectedShape = (shape: string) => {
  switch (shape) {
    case 'shape-1':
      return <ShapeOne />
    case 'shape-2':
      return <ShapeTwo />
    case 'shape-3':
      return <ShapeThree />
    case 'shape-4':
      return <ShapeFour />
    case 'squiggle':
      return (
        <div
          className="cta__squiggle abs top left x"
          style={{ backgroundImage: 'url(/squiggle.png)' }}
        />
      )
    default:
      break
  }
}

export interface ShapeProps {
  data: {
    backgroundColor: string
    shapeType: string
    position: boolean
    shapeColor: string
  }
}

export const Shape = ({ data }: ShapeProps) => {
  const { backgroundColor, shapeType, position, shapeColor } = data

  return (
    <div
      className={cx('wave rel x flip', `bg-${backgroundColor}`, shapeColor,
        {
          'abs z1': position,
        })
      }
    >
      {selectedShape(shapeType)}
    </div>
  )
}
