import React from 'react'
import { Link } from 'gatsby'

import { Image } from 'src/components/image'
import { SmallWave, SmallArrow, SideWave } from 'src/components/svgs'
import classes from './locationCard.module.css'

export const LocationCard = ({
  content: {
    main: {
      title,
      address,
      image,
      logo,
      enableLanding,
      ctaText,
      badge,
      services,
      slug,
    },
  },
  horizontal,
}: {
  content: {
    main: {
      enableLanding: boolean
      title: string
      address: string
      ctaText?: string
      image?: {
        asset: {
          _id: string
        }
      }
      logo?: {
        asset: {
          _id: string
        }
      }
      badge?: string
      services?: any[]
      slug: {
        current: string
      }
    }
  }
  horizontal?: boolean
}) => (
  <React.Fragment>
    <div
      className={`location__card ${
        horizontal ? classes.horizontal : ''
      } bcw rel cb f fw jcb aist ${classes.locationCard}`}
    >
      {enableLanding && (
        <Link
          className="abs z2 x y top left"
          to={`/locations/${slug.current}`}
        />
      )}
      {image && image?.asset?._id && (
        <div className="x rel rel">
          {badge && (
            <div className="abs ps20 left z1 top">
              <span
                className={`px1 py05 br5 caps mono cw bg-primary-3 ${classes.badgeText}`}
              >
                {badge}
              </span>
            </div>
          )}
          <Image className="x" imageId={image.asset._id} alt={title} />
          <div
            className={`${horizontal ? classes.hidden : ''} ${
              horizontal ? classes.bottomwave : ''
            } cw abs bottom x left`}
          >
            <SmallWave className="x block" />
          </div>
          <div
            className={`${classes.hidden} ${
              horizontal ? classes.sidewave : ''
            } cw abs top y right`}
          >
            <SideWave className="y" />
          </div>
        </div>
      )}
      <div
        className={`location__card-info f jcb col col-l-4 x rel z3 px1 ${classes.cardInfo}`}
      >
        <div>
          {enableLanding ? (
            <Link className="" to={`/locations/${slug.current}`}>
              <h3 className={`mb20 ${classes.subTitle}`}>{title}</h3>
            </Link>
          ) : (
            <h3 className="mb20">{title}</h3>
          )}
          <p
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: address?.replace(/\n/gi, '<br />'),
            }}
          />
          {services && services.length > 0 && (
            <div className="s18">
              <span className="bold">Services:</span>{' '}
              {services.map((service, i) => (
                <React.Fragment key={service.id}>
                  <Link
                    className="underline"
                    to={`/services/${service.content.main.slug.current}`}
                  >
                    {service.content.main.title}
                  </Link>
                  <span>{services.length > i + 1 && ', '}</span>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        <div className={horizontal ? classes.bottomContainer : ''}>
          <div className="pb30">
            {enableLanding && (
              <Link
                className="f aic s18 bold"
                to={`/locations/${slug.current}`}
              >
                <span className="underline mr15">
                  {ctaText ? ctaText : 'See clinic details'}
                </span>{' '}
                <SmallArrow />
              </Link>
            )}
          </div>
          {logo && logo?.asset?._id && (
            <div
              className={`${classes.logoContainer} insurance__module-single`}
            >
              <Image imageId={logo.asset._id} />
            </div>
          )}
        </div>
      </div>
    </div>
  </React.Fragment>
)
