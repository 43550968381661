import { fetcher } from './fetcher'

const BASE_API_URL = process.env.GATSBY_WWW_URL

export type createLeadIdentification = {
  email: string
  campaignName: string
  eventProperties?: any
}

type createLeadPayload = createLeadIdentification | any

export const saveLead = async (data: createLeadPayload) => {
  return fetcher(
    `${BASE_API_URL}/api/v1/marketing/lead`,
    JSON.stringify(data),
    'POST'
  )
}