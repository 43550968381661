import React, { useState } from 'react'
import cx from 'classnames'
import classes from './locationsList.module.css'

import { LocationCard } from 'src/components/locations/locationCard'

export interface LocationsListProps {
  data: {
    locations: any[]
    backgroundColor?: string
    spacing?: string
    title?: string
    template: 'small' | 'large'
  }
}

export const LocationsList = ({ data }: LocationsListProps) => {
  const [showMore, onShowMore] = useState<boolean>(false)

  const { backgroundColor, locations, title, spacing, template } = data

  return (
    <div
      className={cx('py1 rel', spacing, backgroundColor, {
        cw: backgroundColor === 'bcb',
        'cw bcb': backgroundColor === undefined,
      })}
    >
      <div className="container--l outer mxa">
        {title && <h2>{title}</h2>}
        <div
          className={`${classes.locationsList}  ${
            template === 'small' ? classes.listSmall : classes.listLarge
          }`}
        >
          {locations?.map((location, i) => (
            <div
              className={`${
                i >= 9 && (template === 'small') === true && !showMore
                  ? 'hide'
                  : ''
              }`}
            >
              <LocationCard key={location.id} {...location} />
            </div>
          ))}
        </div>
        <div className="cursor f fw button--block mb1 pt30 mb20 jcs aic">
          {locations?.length > 9 && template === 'small' && (
            <span
              onClick={() => onShowMore(!showMore)}
              className="inline-block rel button no-underline f z1 rel"
            >
              {!showMore ? 'Show More' : 'Show Less'}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
